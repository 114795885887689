import React from "react";
import Seo from '../components/Seo'
import SectionTitle from "../components/SectionTitle";
import Layout from "../components/Layout";
import projects from '../../content/projects.json';
import ProjectOnList from "../components/projects/ProjectOnList";

export default function Home() {
    return (
        <>
        <Seo title="Portfolio" description="Chcesz wiedzieć jakie są efekty mojej pracy? Zobacz moje portfolio stron internetowych i innych projektów." />
        <Layout title="Wybrane realizacje">

        <section id="portfolio" className="section">
            <div className="container">

                <SectionTitle 
                    title="Portfolio" 
                    subtitle="Chcesz wiedzieć jakie są efekty mojej pracy? Poniżej znajdziesz kilka moich prac."
                />

                <div className="row portfolio">
                    <div className="col-12">
                        <ul className="portfolio__wrapper">
                        {
                            projects.map( (project, i) => <ProjectOnList key={project.slug} project={project} index={i} />)
                        }
                        </ul>
                    </div>
                </div>

            </div>
        </section>
        </Layout>
        </>
    )
}